import React , { Component } from 'react';
import { dropdown } from '../settings/Dropdown.js';
import Loading from '../settings/Loading.js';
import OwlCarousel from 'react-owl-carousel';
import { apiService } from '../../config/api'
import { NavLink, Redirect } from "react-router-dom";
import noimg from '../../img/noimg.png';
import { decimalWithComma } from '../scripts/NumberComma'
import { Pagination, Row, Col, Input, Select, Button } from 'antd'
import queryString from 'query-string'

const Option = Select.Option

class Condo extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
          allData: [],
          data: [],
          loaded: false,
          currentPage: 1,
          showSize: 10,
          serchText: '',
          selSell: null,
          selBuildType: null,
          price: {
            min: null,
            max: null
          }
        }
        this.fetchCondoData = this.fetchCondoData.bind(this)
        this.checkTypeID = this.checkTypeID.bind(this)
        this.onShowSizeChange = this.onShowSizeChange.bind(this)
        this.sellHandle = this.sellHandle.bind(this)
        this.estateHandle = this.estateHandle.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.resetSearch = this.resetSearch.bind(this)
    }

    async componentDidMount(){
      dropdown()
      await this.fetchCondoData()
      const { location, estatetype ,selltype, pricemin, pricemax } = queryString.parse(this.props.location.search)
      this.setState({
        serchText: location === undefined ? '' : location,
        selSell: selltype === undefined ? null : parseInt(selltype),
        selBuildType: estatetype === undefined ? null : parseInt(estatetype),
        price: {
          min: pricemin === undefined ? null : parseInt(pricemin),
          max: pricemax === undefined ? null : parseInt(pricemax)
        }
      })
    }

    async fetchCondoData () {
      let response = await apiService.get('estates')
      let arr = []
      for (var i = 0; i < this.state.showSize; i++) {
        arr.push(response.data[i])
      }
      this.setState({
        allData: response.data,
        loaded: true
      })
      console.log(response.data)
    }

    onShowSizeChange(current, pageSize) {
      this.setState({
        showSize: pageSize,
        currentPage: current
      })
    }

    checkTypeID(type_id) {
        let typename
        if(type_id === "1") typename = "Condominium"
        if(type_id === "2") typename = "Townhouse"
        if(type_id === "3") typename = "Singlehouse"
        if(type_id === "4") typename = "Land House"
        if(type_id === "5") typename = "Detached House"
        if(type_id === "6") typename = "Commercial Building"
        if(type_id === "7") typename = "Hotel & Resort"
        return typename
    }

    sellHandle (event) {
      this.setState({
        selSell: event
      })
    }

    estateHandle (event) {
      this.setState({
        selBuildType: event
      })
    }

    handleChange (event) {
      if (event.target.name === 'pricemax') {
        this.setState({
          price: {
            max: event.target.value,
            min: this.state.price.min
          }
        })
      } else if (event.target.name === 'pricemin') {
        this.setState({
          price: {
            max: this.state.price.max,
            min: event.target.value
          }
        })
      }
      this.setState({
        [event.target.name]: event.target.value
      })
    }

    resetSearch () {
      this.setState({
        serchText: '',
        selSell: null,
        selBuildType: null,
        price: {
          min: null,
          max: null
        }
      })
    }

    checkBoolPrice (price) {
      if (Number(price) <= 0) {
        return false
      } else if (Number(price) == null) {
        return false
      }
      return true
    }

    render() {
        const { loaded, allData, currentPage, showSize, serchText, selSell, selBuildType, price } = this.state
        const sendLoaded = (loaded ? true : false)
        let data = []
        let allEstate = []
        if (allData[0] !== undefined) {
          let arr = []
          allEstate = allData
          if (price.min !== 0 && price.min !== null) {
            allEstate = allEstate.filter(e => {
              return e.estate_price > price.min
            })
          }
          if (price.max !== 0 && price.max !== null) {
            allEstate = allEstate.filter(e => {
              return e.estate_price < price.max
            })
          }
          if (selBuildType !== null && selBuildType !== 0) {
            allEstate = allEstate.filter(e => {
              return e.estate_type_id == selBuildType
            })
          }
          if (selSell !== null) {
            let colName = ''
            if (selSell == 0) colName = 'estate_sale_price'
            else colName = 'estate_rent_price'
            allEstate = allEstate.filter(e => {
              if (e[colName] !== null && Number(e[colName]) > 0) {
                return e
              }
            })
          }
          if (serchText !== '') {
            allEstate = allEstate.filter(e => {
              if (e.estate_address.toLowerCase().match(serchText.toLowerCase())) {
                return e
              } else if (e.estate_refcode.toLowerCase().match(serchText.toLowerCase())) {
                return e
              } else if (e.estate_description.toLowerCase().match(serchText.toLowerCase())) {
                return e
              } else if (e.estate_title.toLowerCase().match(serchText.toLowerCase())) {
                return e
              }
            })
          }

          let start = currentPage !== 1 ? currentPage * showSize : 0
          for (var i = 0; i < showSize; i++) {
            if (allEstate[start + i] !== undefined) {
              arr.push(allEstate[start + i])
            }
          }
          data = arr
        } else {
          data = []
        }

        return(
            <div class="ui fluid pb-100">
                <Loading loaded={sendLoaded} />
                <div class="ui center aligned container pt-100">
                    <div class="ui text container">
                        <h1 class="ui header">
                            Estate Lists
                        </h1>
                    </div>
                    <h4 class="ui header">
                        <div class="sub header">Here you can find latest updated estate related to real estate provide by experts who have high experience in Thailand property. News update, Real estate article , Infrastruce news , Economic & Finance news , Decorate Guide are waiting for you to explore.</div>
                    </h4>
                </div>
                <Row gutter={12} style={{margin: '15px 10% 0 10%'}}>
                  <Col span={8}>
                    <Select value={this.state.selSell} name="selSell" style={{ width: '100%' }} onChange={this.sellHandle}>
                      <Option value={null}>rent & buy</Option>
                      <Option value={0}>Buy</Option>
                      <Option value={1}>Rent</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Input type="number" name="pricemin" value={this.state.price.min} onChange={this.handleChange} placeholder="Minimum Price" />
                  </Col>
                  <Col span={8}>
                    <Input type="number" name="pricemax" value={this.state.price.max} onChange={this.handleChange} placeholder="Maximum Price" />
                  </Col>
                </Row>
                <Row gutter={12} style={{margin: '15px 10% 0 10%'}}>
                  <Col span={8}>
                    <Select value={this.state.selBuildType} name="selBuildType" style={{ width: '100%' }} onChange={this.estateHandle}>
                      <Option value={null}>all estate</Option>
                        <option value={1}>Condominium</option>
                        <option value={2}>Townhouse</option>
                        <option value={4}>Land</option>
                        <option value={5}>Detached House</option>
                        <option value={6}>Commercial Building</option>
                        <option value={7}>Hotel & Resort</option>
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Input onChange={this.handleChange} name="serchText" value={this.state.serchText} placeholder="Address" />
                  </Col>
                  <Col span={4}>
                    <Button onClick={this.resetSearch} type="primary" icon="undo">Reset</Button>
                  </Col>
                </Row>
                <div class="ui container pt-30">
                    {
                      data.length > 0 ?
                        data.map((item, index) => {
                            return  <div key={index} class="ui card green segment mb-50" style={{maxWidth: '100%', minWidth: '100%'}}>
                                        <div class="content" style={{padding: '0'}}>
                                            <div class="ui items">
                                                <div class="item">
                                                    <div class="ui medium image">
                                                    {
                                                        (!item[0].imgs.length > 0 ? <img src={noimg} alt={noimg}  style={{minHeight:'230px',maxHeight:'230px',minWidth:'100%',objectFit:'cover'}} />
                                                            :
                                                            <OwlCarousel
                                                              className="owl-theme owl-carousel owl-demo full-width-img-carousel"
                                                              items={1}
                                                              autoplay={true}
                                                            >
                                                              {
                                                                  item[0].imgs.map((itemimg, imgIndex) =>
                                                                  <div key={imgIndex} class="item">
                                                                      <img src={itemimg.img_base} alt={item.estate_title}  style={{minHeight:'230px',maxHeight:'230px',minWidth:'100%',objectFit:'cover'}} />
                                                                  </div>)
                                                              }
                                                            </OwlCarousel>
                                                        )
                                                    }
                                                    </div>
                                                    <div class="content" style={{padding: '1rem'}}>
                                                        <h2 class="header">{item.estate_title}</h2>
                                                        <div class="meta">
                                                            <span class="cinema">{item.estate_address}</span>
                                                        </div>
                                                        <div class="meta">
                                                            <span class="cinema">{ this.checkBoolPrice(item.estate_sale_price) ? `Sell Price: ${decimalWithComma(item.estate_sale_price)} Baht` : ''}</span>
                                                        </div>
                                                        <div class="meta">
                                                            <span class="cinema">{ this.checkBoolPrice(item.estate_rent_price) ? `Rent Price: ${decimalWithComma(item.estate_rent_price)} Baht` : ''}</span>
                                                        </div>
                                                        <div class="meta">
                                                            <span class="cinema">Ref Code.: {item.estate_refcode}</span>
                                                        </div>
                                                        <div class="meta">
                                                            <span class="cinema">Bedroom: {item.estate_bedroom}</span>
                                                        </div>
                                                        <div class="meta">
                                                            <span class="cinema">Bathroom: {item.estate_bathroom}</span>
                                                        </div>
                                                        <div class="description">
                                                            <p>{item.estate_description}</p>
                                                        </div>
                                                        <div class="extra">
                                                            <div class="ui label"><i class="home icon"></i>{this.checkTypeID(item.estate_type_id)}</div>
                                                            <div class="ui label"><i class="money bill alternate outline icon"></i>{(this.estate_sale_type === 0 ? 'Rent' : 'Buy')}</div>
                                                            <NavLink exact target="_blank" to={"/condo/"+item.estate_id} >
                                                                <div class="ui right floated green button">
                                                                    More
                                                                    <i class="right chevron icon"></i>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }) : 'Not found!'
                    }
                </div>
                <div style={{width: '100%', textAlign: 'center'}}>
                  <Pagination
                    showSizeChanger
                    onChange={this.onShowSizeChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={1}
                    total={allEstate.length}
                    />
                </div>
            </div>
        )
    }
}

export default Condo
