import React, { Component } from 'react';
import { NavLink, Redirect } from "react-router-dom";
import { dropdown } from '../settings/Dropdown.js';

class Content extends React.Component {
    componentDidMount(){
        dropdown()
    }
    constructor() {
      super()
      this.state = {
        show: false,
        classDropdown: 'dropdown-content',
        estatetype: 1,
        minPrice: null,
        maxPrice: null,
        searchText: '',
        sellType: 0,
        turnUrl: '',
        isSearch: false
      }

      this.clickShowDropdownOutside = this.handleClickOutsideDropdown.bind(this)
      this.showDropdown = this.clickShowDropdown.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.submitSearch = this.submitSearch.bind(this)
    }

    submitSearch () {
      let url = '/condo'
      let isFirst = false
      if (this.state.estatetype != null) {
        if (isFirst) {
          url += `&estatetype=${this.state.estatetype}`
        } else {
          url += `?estatetype=${this.state.estatetype}`
        }
        isFirst = true
      }
      if (this.state.minPrice != null) {
        if (isFirst) {
          url += `&pricemin=${this.state.minPrice}`
        } else {
          url += `?pricemin=${this.state.minPrice}`
        }
        isFirst = true
      }
      if (this.state.maxPrice != null) {
        if (isFirst) {
          url += `&pricemax=${this.state.maxPrice}`
        } else {
          url += `?pricemax=${this.state.maxPrice}`
        }
        isFirst = true
      }
      if (this.state.searchText != '') {
        if (isFirst) {
          url += `&location=${this.state.searchText}`
        } else {
          url += `?location=${this.state.searchText}`
        }
        isFirst = true
      }
      if (this.state.sellType != null) {
        if (isFirst) {
          url += `&selltype=${this.state.sellType}`
        } else {
          url += `?selltype=${this.state.sellType}`
        }
        isFirst = true
      }
      this.setState({
        turnUrl: url,
        isSearch: true
      })
    }

    handleChange (event) {
      this.setState({
        [event.target.name]: event.target.value
      })
    }

    clickShowDropdown() {
      if(!this.state.show) {
        document.addEventListener('click', this.clickShowDropdownOutside, false)
      } else {
        document.removeEventListener('click', this.clickShowDropdownOutside, false)
      }
      this.setState({ show: !this.state.show })
    }

    handleClickOutsideDropdown(e) {
      if(this.node.contains(e.target)) {
        return;
      }
      this.clickShowDropdown()
    }


    render() {
      if (this.state.isSearch) {
        this.setState({
          isSearch: false
        })
        return <Redirect push to={this.state.turnUrl}/>
      }

      let buttonClass1 = 'ui button'
      let buttonClass2 = 'ui button'
      if (this.state.sellType == 0) {
        buttonClass1 = `ui positive button`
      } else if (this.state.sellType == 1) {
        buttonClass2 = `ui positive button`
      }

      console.log(this.state.sellType)

      const showIsOn = this.state.show ? ' show' : ''
      const classNameDropdown = this.state.classDropdown + showIsOn
      return (
        <div class="hero">
          <div class="center-content">
            <h3>A door to your perfect home</h3>
            <div class="flex-row">
              <div class="ui buttons">
                <button onClick={this.handleChange} name="sellType" value={0} class={buttonClass1}>Buy</button>
                <div class="or"></div>
                <button onClick={this.handleChange} name="sellType" value={1} class={buttonClass2}>Rent</button>
              </div>
            </div>
            <div class="ui grid centered mt-20">
              <div class="two wide computer fourteen wide tablet fourteen wide mobile column">
                <select name="estatetype" onChange={this.handleChange} value={this.state.estatetype} class="ui selection dropdown">
                  <option value="1">Condominium</option>
                  <option value="2">Townhouse</option>
                  <option value="4">Land</option>
                  <option value="5">Detached House</option>
                  <option value="6">Commercial Building</option>
                  <option value="7">Hotel & Resort</option>
                </select>
              </div>
              <div class="two wide computer fourteen wide tablet fourteen wide mobile column" ref={node => { this.node = node}}>
                <button class="ui search selection dropdown icon" onClick={this.showDropdown}>Price<i class="dropdown icon"></i></button>
                <div className={classNameDropdown}>
                  <div class="ui form">
                    <div class="ui action fluid input">
                      <div class="column-left-in-dropdown">
                        <span class="text-in-column-dropdown">MIN</span>
                      </div>
                      <input type="number" name="minPrice" value={this.state.minPrice} onChange={this.handleChange} placeholder="ANY" style={{borderTopLeftRadius:'0',borderBottomLeftRadius:'0'}} />
                      <div class="column-left-in-dropdown">
                        <span class="text-in-column-dropdown">THB</span>
                      </div>
                    </div>
                  </div>
                  <div class="ui form pt-10">
                    <div class="ui action fluid input">
                      <div class="column-left-in-dropdown">
                        <span class="text-in-column-dropdown">MAX</span>
                      </div>
                      <input type="number" name="maxPrice" value={this.state.maxPrice} onChange={this.handleChange} placeholder="ANY" style={{borderTopLeftRadius:'0',borderBottomLeftRadius:'0'}} />
                      <div class="column-left-in-dropdown">
                        <span class="text-in-column-dropdown">THB</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="five wide computer fourteen wide tablet fourteen wide mobile column">
                <div class="ui action input">
                  <input type="text" name="searchText" value={this.state.searchText} onChange={this.handleChange} placeholder="Area, BTS & MRT station or Property name" />
                  <button onClick={this.submitSearch} class="ui button icon">
                    <i class="search icon"></i>
                    Search
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

export default Content
