import React, {Component} from 'react'
import img1 from '../../img/propertyimg1.jpg';
import img2 from '../../img/propertyimg2.jpg';
import img3 from '../../img/propertyimg3.jpg';
import img4 from '../../img/propertyimg4.jpg';

class PropertyManagement extends Component {
    constructor (props) {
      super(props)
    }

    render() {
        return (
            <div class="ui container pt-100 pb-100">
                <div class="ui center aligned text container">
                    <h1 className="h1">Property Management</h1>
                </div>
                <div class="ui aligned container pt-30">
                    <h2>A Simple Solution to Property Management for Global Property Investors</h2>
                    <p>
                      It may come as a surprise for many overseas buyers to discover that Property Management, as a
                      turnkey rental service, it is a relatively new business concept for condo landlords in Thailand!
                      The vast majority of buy-to-let investors in the Capital are overwhelmingly Thai and they often
                      tend to shy away from Professional Property Management Services for the following reasons:
                    </p>
                    <ul>
                      <li>They are often based locally and can attend to on-going condo issues rapidly.</li>
                      <li>They do not trust Property Management companies to deliver cost-efficient and reliable service.</li>
                      <li>Sensitive to additional rental costs.</li>
                    </ul>
                    <p>
                      However foreign landlords based overseas, may often find peace of mind knowing that their
                      asset and tenant is being assisted and monitored in a timely manner!
                    </p>

                    <h2>
                      Our Property Management Services Scope:
                    </h2>
                    <p>
                      In a perfect world, you would rent your property to a long-term tenant who would keep the
                      property in perfect condition and have no problems but the real world often presents different
                      issues which we are ready to remedy if they happen. The goal of <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业
                      )</strong> management is to maintain a smooth, problem-free relationship between property, tenant and
                      owner.
                    </p>
                    <ul>
                      <li>Find Tenants</li>
                      <li>Handling security deposits</li>
                      <li>Leases</li>
                      <li>Rent Collection</li>
                      <li>Safety and property conditions checks</li>
                      <li>Setting up services (cable, internet, etc.) for tenants</li>
                      <li>Arrangement of maintenance/cleaning services</li>
                      <li>Inspections</li>
                      <li>Problem management</li>
                      <li>Terminating leases</li>
                      <li>Evictions</li>
                    </ul>

                    <h2>
                      The Benefits of <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong> Rental Management Service
                    </h2>
                    <ul>
                      <li>
                        <strong>Less chances for a Problem Tenant</strong> – As a Wit Realty (韦特置业) we see hundreds to
                        thousands of prospective tenants. We know how to spot red flags associated with of
                        problem tenants
                      </li>
                      <li>
                          <strong>Decreased Tenant Turnover</strong> – It starts by finding the right tenant and when these
                          tenants experience a problem-free, well managed living experience, they are more likely
                          to extend their lease and become long-time, income generating tenants,
                      </li>
                      <li>
                          <strong>Timely Rent Payment</strong> – With proper lease policies and consistent rent collection, you
                          will receive your rent on time every month.
                      </li>
                      <li>
                          <strong>Maintains Property Condition</strong> – With problem-free, long-term tenants and timely
                          maintenance your property will maintain its condition most efficiently, decreasing the
                          cost of future home improvement which will help improve your ROI in the long term.
                      </li>
                      <h3 style={{ marginTop: "10px" }}>
                        What's good for property owners?
                      </h3>
                      <li>
                          <strong>Less Stress</strong> – You will completely avoid every issue ranging from
                          small problems hanging a piece of art or as big as a late night emergency. You can rest
                          assured your property will not be harmed by poor tenants or lack of necessary
                          maintenance by the sometimes incompetent juristic offices and you will not be wasting
                          your time preparing mundane paperwork.
                      </li>
                      <li>
                          <strong>More Freedom</strong> – Where you personally live should not play a factor in where you invest
                          in property. With <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong> management you can live and travel anywhere
                          you desire regardless of any issues with your property or your tenant's needs.
                      </li>
                      <li>
                          <strong>More Free Time</strong> – For the real estate investor, you surely believe that 'time is money'
                          and by using <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong> management your time can be
                          more profitably spent researching and obtaining new projects to add to your Thailand
                          Real Estate investment portfolio. You will never have to sacrifice any time with your
                          friends and family dealing with any concerns about your property.
                      </li>
                      <li>
                          <strong>More Income</strong> - Since, we have the experience for this business, why don't let us help
                          you optimize the rent with 3 amazing options that could makes your property becomes
                          one your another source of stable income. <strong style={{ color: "rgb(199, 18, 65)" }}>(to know more about the 3 options, please
                          contact us)</strong>
                      </li>
                    </ul>

                    <h2>Rental Commission</h2>
                    <p>
                        Commission to rent out the property for 1 year is equivalent to 1 month rental fee and if the
                        tenant signs the agreement for more than 1 year, from the 2 nd year onwards, the commission fee
                        is equivalent to half month rental fee for each year, for example:
                    </p>
                    <ul>
                      <li>1 year rent = 1 month commission</li>
                      <li>2 years rent = 1.5 months commission</li>
                      <li>3 years rent = 2 months commission</li>
                    </ul>

                    <h2>Property Management Service</h2>
                    <p>
                        Commission to rent out the property for 1 year is equivalent to 1 month rental fee and if the
                        tenant signs the agreement for more than 1 year, from the 2 nd year onwards, the commission fee
                        is equivalent to half month rental fee for each year, for example:
                    </p>
                    <ul>
                      <li>
                          Fee is calculated by 1.25% of the monthly rental fee – meaning it's just only 15% from
                          the entire one-year rental income, but less headache and save more time for the landlord
                      </li>
                      <li>
                          We help landlord settle the electricity, water, repair, and cleaning bills in advance before
                          the tenant moves in, and bill the landlord right when you receive your rental income from
                          the tenant, yet we do not provide advance cover for yearly common fee or property tax.
                      </li>
                      <li>
                          Once the premises is occupied, the landlord will receive the report about the rental
                          payment, maintenance charges (if any), and the tenant status and condition by monthly
                          basis
                      </li>
                    </ul>
                </div>

                <div class="ui aligned container pt-30">
                    <h2>为全球资产投资者的物业管理解决方案</h2>
                    <p>
                        对于泰国公寓业主来说，一个较新的商业概念即交钥匙租赁服务
                        ——物业管理方式，将会给许多海外买家带来惊喜。
                        在曼谷大部分“买入卖出”的投资者都是泰国人，他们往往会回远离
                        专业物业管理服务，原因如下：
                    </p>
                    <ul>
                      <li>他们一般在当地工作，可以轻易且快速地处理公寓发生的问题。</li>
                      <li>他们不相信物业管理公司所提供的节省成本且可靠的服务。</li>
                      <li>对增加租赁成本比较敏感。</li>
                    </ul>
                    <p>
                        但是，对海外业主来说，他们的资产和租户得到及时的协助和监控，
                        这才会让他们觉得放心！
                    </p>

                    <h2>
                      我们的物业管理服务范围：
                    </h2>
                    <p>
                      理想情况下，业主获得长期租户且他们会保持房产完好无损，不会
                      有任何问题。但，现实是会经常出现不同的问题，且在问题出现时
                      要有预见性的解决方法。 <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong>物业管理目标是
                      为物业、租户、业主三者间建立起快速流畅无障碍的关系。
                    </p>
                    <ul>
                      <li>寻找租户</li>
                      <li>处理保证金</li>
                      <li>租赁</li>
                      <li>租金收取</li>
                      <li>安全及物业状况检查</li>
                      <li>为租户提供加装服务（如有线电视，互联网等</li>
                      <li>维修/清洁的安排服务</li>
                      <li>检查</li>
                      <li>问题管理</li>
                      <li>终止租约</li>
                      <li>搬迁</li>
                    </ul>

                    <h2>
                      <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong> 租赁管理服务的优点
                    </h2>
                    <ul>
                      <li>
                        <strong>降低租赁问题发生的可能性</strong> — <strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong>已服务数千
                        例租赁业务，我们熟知租赁问题发生的可能性，并能事先协调解决
                        好问题。
                      </li>
                      <li>
                          <strong>降低租户周转率</strong> —首先找到合适的租户，并为租户提供无障碍的，
                          优质管理下的生活体验，使租户有延长租期的意愿，并成为长期租
                          户，提升租赁收益。
                      </li>
                      <li>
                          <strong>按时及付租金</strong> — 通过适当的租约政策和稳定的租金收取，您将每
                          月按时收到租金。
                      </li>
                      <li>
                          <strong>保持物业状况</strong> — 基于无障碍沟通及时 服务，使得您的物业在长期
                          租户和及时维修基础下有效保持物业状况，降低未来家居装修的成
                          本，将有助提高您的投资回报率。
                      </li>
                      <h3 style={{ marginTop: "10px" }}>
                        对业主有什么帮助？
                      </h3>
                      <li>
                          <strong>减轻压力</strong> —— 您将完全无忧于任何一个问题，例如：小问题小到
                          一件装饰品的挂钩问题，大问题大到发生深夜紧急事件。您可以放
                          心您的物业不会被困难租户损坏，也不必担心会有低效行政行为导
                          致物业未能有效维修，您也无须去准备那些琐碎的文书工作，将节
                          省您的许多时间。
                      </li>
                      <li>
                          <strong>拥有更多自由空间</strong> —— 如果您投资的物业并不是您个人日常生活
                          之地，不须担心，在<strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong>的物业管理服务下，您可
                          以随意旅行和生活在任何地方，而不必为您的物业和您的租户而操心。
                      </li>
                      <li>
                          <strong>拥有更多自由时间</strong> ——作为一名真正的资产投资者，“时间就是金
                          钱”对您来说非常重要，在<strong style={{ color: "rgb(1, 148, 65)" }}>Wit Realty (韦特置业)</strong>的服务下，您将更
                          有效地使用时间来研究和新增项目，未来优化您在泰国的房户投资
                          组合，绝对不需要花费您与家人、朋友的温擎时间，而去忧心和外
                          理您的物业。
                      </li>
                      <li>
                          <strong>拥有更多收入</strong> ——正因我们具备行业资深服务经验，<strong style={{ color: "rgb(199, 18, 65)" }}>我们拥有三种
                         有吸引力的有效方案，可以为客户延伸资产收益成为另外的稳定收
                         入来源。为什么不让我们帮助您来优化您的资产租赁方案呢？（想更多了解这三大有效方案，请联系我们）</strong>
                      </li>
                    </ul>

                    <h2>租赁服务收费标准</h2>
                    <p>
                        出租房产1年的佣金相当于1个月的租赁费，如果租户签署协议超过
                        1年，从第2年开始，佣金相当于每年半个月的租赁费，例：
                    </p>
                    <ul>
                      <li>1年租金= 1个月佣金</li>
                      <li>2年租金= 1.5个月佣金</li>
                      <li>3年租金= 2个月佣金</li>
                    </ul>

                    <h2>收费标准</h2>
                    <p>
                        费用按照每月租金的1.25％ - 这意味着只占整个一年租金收入的
                        15％，但减少了房东烦恼并为房东节省了更多时间。
                    </p>
                    <ul>
                      <li>
                          费用按照每月租金的1.25％ - 这意味着只占整个一年租金收入的
                          15％，但减少了房东烦恼并为房东节省了更多时间。
                      </li>
                      <li>
                          我们帮助房东在租户搬入之前提前清算电费，水费，维修费和清
                          洁费，并在收到房客的租金收入时立即向房东开账单，但我们不提
                          前支付任何年度性税费及财产税。
                      </li>
                      <li>
                          当服务协议生效后，房东将每月收到有关租金支付记录、维修维
                          护(诺若有需要)账单，以及租户情况及物业状况等报告。
                      </li>
                    </ul>
                </div>
            </div>
        )
    }

}

export default PropertyManagement
