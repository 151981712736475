import axios from 'axios'

let DEFAULT_DOMAIN = "https://www.witrealty.co/api/"
if (process.env.NODE_ENV === 'production') {
    DEFAULT_DOMAIN = "https://www.witrealty.co/api/"
}

export const apiService = axios.create({
  baseURL: DEFAULT_DOMAIN,
})
